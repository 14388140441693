.inputBar{
    margin: auto;
    width: 40rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
}
@media (max-width: 700px) {
    .inputBar{
        width: 22rem;
  }
}